import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';
import styles from './ChartRect.module.scss';

export interface IValue {
    /**
     * Координата X абсолютная
     */
    x: number;

    /**
     * Координата Y абсолютная
     */
    y: number;

    /**
     * Значение в соответствии с графиком
     */
    value: number|string;

    /**
     * Название
     */
    title: string;
}

interface IProps {
    /**
     * Значение, над которым сейчас находится курсор
     */
    value: IValue|null;

    /**
     * Текущая ширина графика
     */
    width: number;

    /**
     * Значение по оси y1
     */
    y1?: number;

    /**
     * Значение по оси y2
     */
    y2?: number;

    /**
     * Ширина столбика, чтобы отображать двигающийся задник такой же ширины, что и столбик
     */
    lineWidth: number;
}

/**
 * Элемент для работы с ховером на столбчатых графиках
 */
export default function ChartRect({
    y2,
    y1,
    width,
    value,
    lineWidth,
}: IProps) {
    const lineRef = useRef(null);

    const [tooltipPosition, setTooltipPosition] = useState({
        top: 0,
        left: 0,
    });

    useEffect(() => {
        if (!lineRef.current) {
            return;
        }

        const boundingClientRect = lineRef.current.getBoundingClientRect();
        const parentBoundingClientRect = lineRef.current.parentElement.getBoundingClientRect();
        const topValue = boundingClientRect.top + window.scrollY;
        const leftValue = parentBoundingClientRect.left + value.x;

        setTooltipPosition({
            top: topValue,
            left: leftValue,
        });
    }, [lineRef.current, width, value]);

    /**
     * Отобжраем тултип на нужном месте по X относительно главной линии
     */
    const tooltipTranslate = useMemo(() => {
        const halfWidth = width/2;

        /**
         * Если находимся на первой половине графика, то отображаем с правой стороны,
         * чтобы график не уходил за пределы экрана
         */
        if (halfWidth < value.x) {
            return `calc(-${lineWidth/2}px - 101%)`;
        }

        /**
         * Если находимся на первой половине графика, то отображаем с левой стороны,
         * чтобы график не уходил за пределы экрана
         */
        return `calc(${lineWidth/2}px + 1%)`;
    }, [width, value, lineWidth]);

    /**
     * Значение по Y для тултипа
     */
    const tooltipYValueRange = useMemo(() => {
        const lowerLineValue = Math.abs(y1-y2);

        // Не выходить за нижнюю границу графика
        if (value.y > lowerLineValue) {
            return lowerLineValue;
        }

        // Не выходить за верхнюю границу графика
        if (value.y < y1) {
            return y1;
        }

        // Отображаем тултип на уровне, с верхним концом столбика
        return value.y;
    }, [width, value, y2, y1]);

    return (
        <>
            <line
                x1={0}
                x2={0}
                y1={y1}
                y2={y2}
                stroke="#0000000A"
                strokeWidth={lineWidth}
                style={{
                    transform: `translateX(${value.x}px)`,
                }}
                className={styles['line-container']}
                ref={lineRef}
            />
            {createPortal(
                <div
                    style={{
                        left: tooltipPosition?.left,
                        top: tooltipPosition?.top,
                        transform: `translate(${tooltipTranslate}, calc(${tooltipYValueRange}px - 100%))`,
                    }}
                    className={styles['tooltip-container']}
                >
                    <div className={styles['tooltip-header']}>
                        {value.title}
                    </div>
                    <div className={styles['tooltip-content']}>
                        <div className={styles['tooltip-content-item']}>
                            Кредитный портфель:{' '}
                            <span className={styles['tooltip-content-value']}>{value.value} млрд</span>
                        </div>
                    </div>
                </div>,
                document.body)}
        </>
    );
};
