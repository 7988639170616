'use client';

import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import styles from './ExpandControl.module.scss';

interface IProps {
    /**
     * Класс для чекбокса
     */
    checkboxClassName?: string;
    /**
     * Класс для текста
     */
    labelClassName?: string;
    /**
     * ID области, которую необходимо сворачивать
     */
    id: string;
}

export default function ExpandControl({
    checkboxClassName,
    labelClassName,
    id,
}: IProps) {
    const controlRef = useRef<HTMLInputElement>(null);
    const inputId = `input_${id}`;
    const [checked, setChecked] = useState(false);

    const didChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (event?.target) {
            setChecked(event.target.checked);

            if (!event.target.checked) {
                controlRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, []);

    useEffect(() => {
        const mainText = document.getElementById(id);
        if (mainText) {
            if (checked) {
                mainText.style.height = `${mainText.scrollHeight}px`;
            } else {
                mainText.style.removeProperty('height');
            }
        }
    }, [checked, id]);

    return (
        <>
            <label
                className={mergeClassNames([styles['label'], labelClassName])}
                htmlFor={inputId}
            >
                {checked ? 'Свернуть' : 'Развернуть'}
            </label>
            <input
                className={mergeClassNames([styles['checkbox'], checkboxClassName])}
                type="checkbox"
                checked={checked}
                onChange={didChanged}
                ref={controlRef}
                id={inputId}
            />
        </>
    );
};
