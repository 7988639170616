import React, {
    forwardRef,
    useRef,
    useLayoutEffect,
    useMemo,
    useImperativeHandle,
} from 'react';
import { IConnectableInputProps } from '@frontend/jetlend-core/src/ui/inputs/common';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import { buildClassNames } from '../../../utils/classNameUtils';

import styles from './simpleTextInput.module.scss';
import useInputControl from '@ui/hooks/useInputControl';

export interface IProps extends IConnectableInputProps {
    label?: string;
    postfix?: string;
    disabled?: boolean;
    placeholder?: string;
    size?: 'default'|'large';
    width?: number;
    noMargin?: boolean;
    autoFocus?: boolean;
    onKeyDown?: (eventOrData: any) => void;
    fullWidth?: boolean;
    testId?: string;

    onKeyDownCapture?: (event: React.KeyboardEvent) => void;
    onPasteCapture?: (event: React.ClipboardEvent) => void;
}

const SimpleTextInput = forwardRef<HTMLInputElement, IProps>((props, ref) => {
    const {
        size = 'default',
        value,
        onChange,
        onKeyDown,
        onBlur,
        onFocus,
        onKeyDownCapture,
        onPasteCapture,
        error,
    } = props;

    const hasLabel = typeof props.label === 'string' && props.label.length > 0;
    const hasError = props.invalid && !props.pristine;

    const inputWrapperRef = useRef<HTMLDivElement>();

    // Используем useImperativeHandle, чтобы связывать ref из forwardRef с локальным localRef
    const localRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => localRef.current);
    const didInputChanged = useInputControl(value, localRef, onChange);

    useLayoutEffect(() => {
        const inputElement = inputWrapperRef?.current?.querySelector<HTMLInputElement>('input');
        if (!inputElement) {
            return;
        }

        if (props.autoFocus && !props.disabled) {
            inputElement.focus();
        }
    }, [ props.autoFocus, props.disabled, inputWrapperRef ]);

    const containerClassName = buildClassNames(styles, [
        'container',
        `container--size-${size}`,
        !props.noMargin && 'container--margin',
        hasError && error && 'container--error',
    ]);

    const labelWrapperClassName = buildClassNames(styles, [
        'label-wrapper',
        !hasLabel && 'label-wrapper--vertical',
        props.disabled && 'label-wrapper--disabled',
    ]);

    const labelTextClassName = buildClassNames(styles, [
        'label__text',
        hasError && error && 'label__text--error',
    ]);

    const inputTextClassName = buildClassNames(styles, [
        'input',
        !hasLabel && 'input--no-label',
        hasError && 'input--error',
        props.disabled && 'input--disabled',
        props.postfix && 'input--with-postfix',
    ]);

    const inputWrapperClassName =  props.fullWidth ? styles['input-wrapper--full-width'] : '';

    const customInputStyles = useMemo<React.CSSProperties|undefined>(() => {
        if (typeof props.width === 'number' && props.width > 0) {
            return {
                width: props.width,
                minWidth: props.width,
            };
        }

        return undefined;
    }, [ props.width ]);

    return (
        <div className={containerClassName}>
            <label className={labelWrapperClassName}>
                {hasLabel && (
                    <div className={styles['label']}>
                        <div className={labelTextClassName}>{props.label}</div>
                        {hasError && error &&
                            <div className={styles['label__error']}>{error}</div>
                        }
                    </div>
                )}

                <div ref={inputWrapperRef} className={inputWrapperClassName}>
                    <input
                        ref={localRef}
                        className={inputTextClassName}
                        type="text"
                        placeholder={props.placeholder}
                        value={value}
                        disabled={props.disabled}
                        onChange={didInputChanged}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDownCapture={onKeyDownCapture}
                        onPasteCapture={onPasteCapture}
                        style={customInputStyles}
                        data-testid={props.testId}
                    />
                    {props.postfix &&
                        <div className={styles['input__postfix']}>{props.postfix}</div>
                    }
                </div>
                {!hasLabel && hasError && error &&
                    <div className={styles['label__error']}>{error}</div>
                }
            </label>
        </div>
    );
});

export const SimpleTextInputField = connectToField(SimpleTextInput);

export default SimpleTextInput;