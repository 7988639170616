import React, { useMemo } from 'react';
import { ICreditPortfolioChartValues } from '@app/models/investments/investments';
import styles from './YearsAxis.module.scss';

/**
 * Значения оффсета для правильного отображения подписей
 */
const OFFSET_VALUES = {
    4: 2.5,
    3: 2,
    2: 1.5,
    1: 1,
};

/**
 * Высота линий, разделяющих года на секции
 */
const LINE_HEIGHT = 10;

/**
 * Расстояние от оси до лейблов
 */
const LABELS_TOP_OFFSET = 20;

interface IProps {
    /**
     * Координата Y1
     */
    y1: number;

    /**
     * Данные графика
     */
    data: ICreditPortfolioChartValues;

    /**
     * Функция, которая определяет абсолютную точку на оси X, по относительной величине X
     */
    scale: {
        x: (x: number) => number;
    };
}

/**
 * Компонент с годами для маленьких экранов
 */
export default function YearsAxis({
    y1,
    data,
    scale,
}: Partial<IProps>) {

    /**
     * Получаем данные о годах
     *
     * Какой год
     *
     * Значение по X только от первого элемента
     *
     * Количество кварталов в году
     */
    const yearsData: Record<number, { x: number; times: number }> = useMemo(() => {
        if (!data) {
            return {};
        }

        return data.reduce((acc, item) => {
            const year = parseInt(item.title.split('-')[0], 10);
            if (!acc[year]) {
                acc[year] = {
                    x: item.x-1,
                    times: 0,
                };
            }
            acc[year].times += 1;
            acc[year].x = Math.min(acc[year].x, item.x);
            return acc;
        }, {});
    }, [data]);

    /**
     * Количество годов на графике
     */
    const yearsAmount = Object.keys(yearsData).length;

    return (
        <g className={styles.container}>
            {Object.entries(yearsData).map(([key, value]) => (
                <text
                    dy={LABELS_TOP_OFFSET}
                    y={y1}
                    x={scale.x(OFFSET_VALUES[value.times] + value.x)}
                    textAnchor="middle"
                    key={key}
                >
                    {key} г
                </text>
            ))}
            {/* Т.к. крайние линии мы не рисуем, то нужно сделать на 1 линию меньше */}
            {Array.from({ length: yearsAmount-1 }).map((_, index) => {
                return (
                    <line
                        y1={y1}
                        y2={y1+LINE_HEIGHT}
                        stroke="#00000096"
                        x1={scale.x(((index+1)*4)+0.5)}
                        x2={scale.x(((index+1)*4)+0.5)}
                        key={index}
                    />
                );
            })}
        </g>
    );
};
