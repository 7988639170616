'use client';

import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useResizeObserver } from '@frontend/jetlend-web-ui/src/hooks/useResizeObserver';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryCursorContainer,
} from 'victory';
import moment from 'moment/moment';
import styles from './InvestmentsCreditPortfolioChart.module.scss';
import InvestmentsCreditPortfolioChartAxisTick from '@app/components/client/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChart/InvestmentsCreditPortfolioChartAxisTick/InvestmentsCreditPortfolioChartAxisTick';
import {
    getBarCornerRadius,
    getChartPadding,
    getDomainPadding,
    getFormattedData,
    getXAxisTickValues,
    getYAxisValues,
} from './InvestmentsCreditPortfolioChart.logic';
import { creditPortfolioChartHandler } from '@app/ducks/investments';
import ChartRect, { IValue } from '@app/components/client/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChart/ChartRect/ChartRect';
import { QUARTERS_MAP } from '@app/models/investments/investments';
import YearsAxis from '@app/components/client/investments/InvestmentsCreditPortfolio/InvestmentsCreditPortfolioChart/YearsAxis/YearsAxis';

/**
 * Стиль текста
 */
export const FONT_FAMILY = 'SF Pro Display, sans-serif';

const BAR_RATIO = 0.8;

/**
 * График кредитного портфеля
 */
export default function InvestmentsCreditPortfolioChart() {
    const containerRef = useRef();
    // Ширина и высота графика для адаптивности
    const [width, height] = useResizeObserver(containerRef);
    const [data] = useSimpleApiHandler(creditPortfolioChartHandler);
    const [selectedBarValue, setSelectedBarValue] = useState<IValue|null>(null);

    const formattedData= useMemo(() => {
        if (!data) {
            return;
        }

        return getFormattedData(data);
    }, [data]);

    const columnWidth = (width/formattedData?.length)*BAR_RATIO;

    const didCursorMoved = useCallback((value, props) => {

        if (value === null) {
            return;
        }
        const xValue = Math.round(value);

        const cursorPosition = props.scale.x(xValue);
        const barValue = formattedData.find(item => item.x === xValue);

        const quarter = moment(barValue?.title).quarter();
        const year = moment(barValue?.title).year();

        const formattedTitle = `${quarter} квартал ${year}, ${QUARTERS_MAP[quarter]}`;

        const formattedBarValue = {
            x: cursorPosition,
            y: props.scale.y(barValue.y),
            title: formattedTitle,
            value: barValue.y.toFixed(1),
        };

        setSelectedBarValue(formattedBarValue);
    }, [formattedData]);

    return (
        <div className={styles['container']} ref={containerRef}>
            <VictoryChart
                width={width}
                height={height}
                padding={getChartPadding(width)}
                domainPadding={{ x: getDomainPadding(width) }}
                containerComponent={(
                    <VictoryCursorContainer
                        cursorDimension="x"
                        onCursorChange={didCursorMoved}
                        cursorComponent={(
                            <ChartRect
                                lineWidth={columnWidth}
                                value={selectedBarValue}
                                width={width}
                            />
                        )}
                    />
                )}
            >
                {/* Ось X */}
                <VictoryAxis
                    tickValues={getXAxisTickValues(formattedData)}
                    style={{
                        axis: {
                            stroke: '#00000096',
                        },
                    }}
                    fixLabelOverlap
                    tickLabelComponent={<InvestmentsCreditPortfolioChartAxisTick data={formattedData} width={width} />}
                />
                <VictoryAxis
                    tickValues={['']}
                    axisComponent={<YearsAxis data={formattedData} />}
                />
                {/* Ось Y */}
                <VictoryAxis
                    style={{
                        axis: { stroke: 'transparent' },
                        grid: {
                            stroke: ({ tickValue }: any) => tickValue === 0 ? 'transparent' : '#00000047',
                            strokeDasharray: '4',
                        },
                    }}
                    dependentAxis
                    tickValues={getYAxisValues(data).values}
                    tickLabelComponent={<InvestmentsCreditPortfolioChartAxisTick dependentAxis width={width} />}
                />
                {/* Группировка колонок */}
                <VictoryBar
                    cornerRadius={{ top: getBarCornerRadius(width) }}
                    data={formattedData}
                    style={{
                        data: { fill: ({ datum }) => datum.color },
                    }}
                    barRatio={BAR_RATIO}
                />
            </VictoryChart>
        </div>
    );
};
