'use client';

import React, {
    ReactNode,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    buildClassNames,
    mergeClassNames,
} from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import Tippy from '@tippyjs/react';
import { Placement } from 'tippy.js';
import { IBasePerson } from '@app/models/aboutPlatform/aboutPlatform';
import styles from './PersonPopper.module.scss';
import Image from '@app/components/server/Image/Image';

export enum PopoverVariant {
    DIRECTOR = 'director',
    DEFAULT = 'default',
}

interface IPersonPopover {
    person: IBasePerson;
    departmentIcon?: string;
    orientation?: Placement;
    children: ReactNode;
    className?: string;
    variant?: PopoverVariant;
}

const PersonPopper = (props: IPersonPopover) => {
    const {
        person,
        departmentIcon,
        orientation = 'right',
        className,
        variant = PopoverVariant.DEFAULT,
        children,
    } = props;

    const emailLinkRef = useRef<HTMLAnchorElement>(null);
    const [isPopoverActive, setPopoverActive] = useState(false);
    const imageShowHandler = useCallback(() => setPopoverActive(true), []);
    const imageHideHandler = useCallback(() => setPopoverActive(false), []);
    const imageClassName = isPopoverActive
        ? mergeClassNames([buildClassNames(styles, ['photo', 'photo__active']), className])
        : mergeClassNames([buildClassNames(styles, ['photo']), className]);

    const strategy = {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    tether: false,
                },
            },
        ],
    };

    const didContentClicked = useCallback((event: React.MouseEvent) => {
        // if target was email link, then stop propagation
        if (event.target === emailLinkRef.current) {
            event.stopPropagation();
            return;
        }
        // else close popover
        setPopoverActive(false);
    }, []);

    const content = useMemo(() => {
        if (variant === PopoverVariant.DIRECTOR) {
            return (
                <div tabIndex={-1} onClick={didContentClicked}>
                    <div className={styles['popover__inner']}>
                        <div className={styles['person__info']}>
                            <p className={styles['name']}>{person.name}</p>
                            <div className={buildClassNames(styles, ['position', departmentIcon && 'with-icon'])}>
                                <p className={styles['position-text']}>{person.position}</p>
                                {departmentIcon && <Image src={departmentIcon} alt="" width={18} height={18} />}
                            </div>
                            <a href={`mailto:${person.email}`} className={styles['email']} ref={emailLinkRef}>
                                {person.email}
                            </a>
                            {person.description && <p className={styles['description']}>{person.description}</p>}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div tabIndex={-1} onClick={didContentClicked}>
                <div className={styles['popover__inner']}>
                    <a href={`mailto:${person.email}`} className={styles['email']} ref={emailLinkRef}>
                        {person.email}
                    </a>
                    <div className={styles['person__info']}>
                        <p className={styles['name']}>{person.name}</p>
                        <div className={buildClassNames(styles, ['position', departmentIcon && 'with-icon'])}>
                            <p className={styles['position-text']}>{person.position}</p>
                        </div>
                        {person.description && <p className={styles['description']}>{person.description}</p>}
                    </div>
                </div>
            </div>
        );
    }, [variant, departmentIcon, person, didContentClicked]);

    return (
        <div className={styles['person']} onMouseLeave={imageHideHandler}>
            <Tippy
                visible={isPopoverActive}
                placement={orientation}
                arrow=""
                className={styles['popover']}
                popperOptions={strategy}
                offset={[0, 0]}
                content={content}
            >
                <div className={imageClassName} onMouseEnter={imageShowHandler}>
                    {children}
                </div>
            </Tippy>
        </div>
    );
};

export default PersonPopper;
